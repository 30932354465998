import { cloneDeep, get } from 'lodash-es';
import { HawkSearchGlobal, HawkSearchState } from '@configuration';
import {
    ContentType,
    ContentZoneItem,
    ContentZones,
    CustomContent,
    CustomWidgetContent,
    DateFacetRange,
    FacetScrolling,
    FacetSearch,
    FacetTruncation,
    FacetType,
    FacetValue,
    FacetValueColor,
    FeaturedItemsContent,
    FeaturedItemsContentItem,
    ImageContent,
    ItemType,
    ModifiedQueryData,
    NumericFacetRange,
    PopularQueriesContent,
    PopularQueriesContentItem,
    QuerySuggestionsData,
    RawFacet,
    RawFacetValue,
    RawFeaturedItem,
    RawMerchandisingItem,
    RawSearchRequest,
    RawSearchResponse,
    RawSearchResultsItem,
    RawSearchResultsItemDocument,
    RawSearchSelection,
    SearchRequest,
    SearchResponse,
    SearchResultsItem,
    SearchTypes,
    SelectedFacet,
    SelectedFacets
} from '@models';
import { BaseService } from '@services';
import { trackingService } from './tracking.service';
import { decodeNestedURI } from '@utilities';

declare let HawkSearch: HawkSearchGlobal;

export class SearchService extends BaseService {
    protected baseUrl = HawkSearch.config.search?.endpointUrl || 'https://searchapi-dev.hawksearch.net';

    private facetExclusionPrefix = HawkSearch.config.search?.facetExclusionPrefix || '-';

    private decodeQuery = HawkSearch.config.search?.decodeQuery || false;
    private decodeFacetValues = HawkSearch.config.search?.decodeFacetValues || false;

    private shouldRenderUI = true;

    // #region Search Operations

    async search(searchRequest: SearchRequest): Promise<SearchResponse> {
        const searchResults = await this.executeSearch(searchRequest, true, false);

        if (this.shouldRenderUI) {
            this.bindComponents(searchResults);
        }
        return searchResults;
    }

    async query(query?: SearchTypes, selectedFacets: SelectedFacets | undefined = undefined, disableSpellcheck = false): Promise<SearchResponse | void> {
        const queryObject = typeof query === 'string' ?
            {
                queryString: query,
                requestType: 'DefaultSearch'
            }
            : query;
        const queryString = queryObject?.queryString;

        const searchRequest: SearchRequest = {
            disableSpellcheck: !!disableSpellcheck || undefined,
            facets: selectedFacets,
            newRequest: true,
            query: queryString,
            requestType: queryObject?.requestType
        };

        if (!this.onSearchPage) {
            const url = this.getSearchUrl(searchRequest);

            window.location.assign(url);

            return Promise.resolve();
        }

        if (queryString) {
            const recentQueries = this.getRecentQueries();
            const recentQueryLimit = 5;

            if (!recentQueries.includes(queryString)) {
                if (recentQueries.length >= recentQueryLimit) {
                    recentQueries.splice(recentQueryLimit - 1);
                }

                recentQueries.unshift(queryString);

                this.saveRecentQueries(recentQueries);
            }
        }

        const searchResponse = await this.executeSearch(searchRequest, true, false);

        if (this.shouldRenderUI) {
            this.bindComponents(searchResponse);
        }

        return searchResponse;
    }

    async addFacetValue(field: string, value: string): Promise<SearchResponse> {
        this.ensureSearchRequest();

        const searchRequest = cloneDeep(HawkSearch.searchRequest!);

        searchRequest.newRequest = false;
        searchRequest.page = undefined;

        if (!searchRequest.facets) {
            searchRequest.facets = {};
        }

        this.clearExistingFacetValue(searchRequest, field, value);

        searchRequest.facets[field] = [...(searchRequest.facets[field]?.filter((v) => v !== value) ?? []), value];

        const searchResponse = await this.executeSearch(searchRequest, false);

        this.bindFacetsListComponent(searchResponse);
        this.bindPaginationComponent(searchResponse);
        this.bindSelectedFacetsComponent(searchResponse);
        this.bindTabsComponent(searchResponse);
        this.bindZoneComponent(searchResponse);

        return searchResponse;
    }

    async removeFacetValue(field: string, value: string): Promise<SearchResponse> {
        this.ensureSearchRequest();

        if (field === 'searchWithin') {
            return await this.searchWithin('');
        }

        const searchRequest = cloneDeep(HawkSearch.searchRequest!);

        searchRequest.newRequest = false;
        searchRequest.page = undefined;

        this.clearExistingFacetValue(searchRequest, field, value);

        const searchResponse = await this.executeSearch(searchRequest, false);

        this.bindFacetsListComponent(searchResponse);
        this.bindPaginationComponent(searchResponse);
        this.bindSelectedFacetsComponent(searchResponse);
        this.bindTabsComponent(searchResponse);
        this.bindZoneComponent(searchResponse);

        return searchResponse;
    }

    includeFacetValue(field: string, value: string): Promise<SearchResponse> {
        const excludedValue = `${this.facetExclusionPrefix}${value}`;

        return this.removeFacetValue(field, excludedValue);
    }

    async excludeFacetValue(field: string, value: string): Promise<SearchResponse> {
        this.ensureSearchRequest();

        const excludedValue = `${this.facetExclusionPrefix}${value}`;
        const searchRequest = cloneDeep(HawkSearch.searchRequest!);

        searchRequest.newRequest = false;
        searchRequest.page = undefined;

        if (!searchRequest.facets) {
            searchRequest.facets = {};
        }

        this.clearExistingFacetValue(searchRequest, field, value);

        searchRequest.facets = {
            ...searchRequest.facets,
            [field]: [...(searchRequest.facets[field] ?? []), excludedValue]
        };

        const searchResponse = await this.executeSearch(searchRequest, false);

        this.bindFacetsListComponent(searchResponse);
        this.bindPaginationComponent(searchResponse);
        this.bindSelectedFacetsComponent(searchResponse);
        this.bindTabsComponent(searchResponse);
        this.bindZoneComponent(searchResponse);

        return searchResponse;
    }

    private clearExistingFacetValue(searchRequest: SearchRequest, field: string, value: string): void {
        value = value.replace(new RegExp('^' + this.facetExclusionPrefix), '');

        const excludedValue = `${this.facetExclusionPrefix}${value}`;

        if (!searchRequest.facets) {
            searchRequest.facets = {};
        }

        searchRequest.facets = {
            ...searchRequest.facets,
            [field]: [...(searchRequest.facets[field]?.filter((v) => v !== value && v !== excludedValue) ?? [])]
        };

        if (!searchRequest.facets[field]?.length) {
            delete searchRequest.facets[field];
        }
    }

    async setFacetValue(field: string, value: string): Promise<SearchResponse> {
        this.ensureSearchRequest();

        const request = cloneDeep(HawkSearch.searchRequest!);

        request.newRequest = false;
        request.page = undefined;

        if (!request.facets) {
            request.facets = {};
        }

        request.facets[field] = [value];

        const searchResponse = await this.executeSearch(request, false);

        this.bindFacetsListComponent(searchResponse);
        this.bindPaginationComponent(searchResponse);
        this.bindSelectedFacetsComponent(searchResponse);
        this.bindTabsComponent(searchResponse);
        this.bindZoneComponent(searchResponse);

        return searchResponse;
    }

    async searchWithin(value: string): Promise<SearchResponse> {
        this.ensureSearchRequest();

        const request = cloneDeep(HawkSearch.searchRequest!);

        request.newRequest = false;
        request.page = undefined;
        request.searchWithin = value || undefined;

        const searchResponse = await this.executeSearch(request, false);

        this.bindFacetsListComponent(searchResponse);
        this.bindPaginationComponent(searchResponse);
        this.bindSelectedFacetsComponent(searchResponse);
        this.bindTabsComponent(searchResponse);
        this.bindZoneComponent(searchResponse);

        return searchResponse;
    }

    async selectTab(value?: string): Promise<SearchResponse> {
        this.ensureSearchRequest();

        const request = cloneDeep(HawkSearch.searchRequest!);

        request.newRequest = false;
        request.page = undefined;

        if (!request.facets) {
            request.facets = {};
        }

        const field = HawkSearch.searchResponse?.facets?.find((f) => f.type === 'tabs')?.field;

        if (field) {
            if (value) {
                request.facets[field] = [value];
            } else {
                delete request.facets[field];
            }
        }

        const searchResponse = await this.executeSearch(request, false);

        this.bindFacetsListComponent(searchResponse);
        this.bindPaginationComponent(searchResponse);
        this.bindSelectedFacetsComponent(searchResponse);
        this.bindTabsComponent(searchResponse);
        this.bindZoneComponent(searchResponse);

        return searchResponse;
    }

    async paginate(page: number): Promise<SearchResponse> {
        this.ensureSearchRequest();

        const request = cloneDeep(HawkSearch.searchRequest!);

        request.newRequest = false;
        request.page = page;

        const searchResponse = await this.executeSearch(request, false);

        this.bindPaginationComponent(searchResponse);

        return searchResponse;
    }

    async setPageSize(pageSize: number): Promise<SearchResponse> {
        this.ensureSearchRequest();

        const request = cloneDeep(HawkSearch.searchRequest!);

        request.newRequest = false;
        request.pageSize = pageSize;

        const searchResponse = await this.executeSearch(request, false);

        this.bindPageSizeComponent(searchResponse);
        this.bindPaginationComponent(searchResponse);

        return searchResponse;
    }

    async sort(value?: string): Promise<SearchResponse> {
        this.ensureSearchRequest();

        const request = cloneDeep(HawkSearch.searchRequest!);

        request.newRequest = false;
        request.page = 1;
        request.sort = value;

        const searchResponse = await this.executeSearch(request, false);

        this.bindPaginationComponent(searchResponse);
        this.bindSortingComponent(searchResponse);

        return searchResponse;
    }

    private async executeSearch(searchRequest: SearchRequest, newSearch: boolean, executeDefaultBindings = true): Promise<SearchResponse> {
        if (this.decodeQuery && searchRequest.query) {
            searchRequest.query = decodeNestedURI(searchRequest.query);
        }

        if (this.decodeFacetValues && searchRequest.facets) {
            for (let facet in searchRequest.facets) {
                let tempArray = [];
                let facetsArray = searchRequest.facets[facet];
                for (let selection in facetsArray) {
                    tempArray.push(decodeNestedURI(facetsArray[parseInt(selection)]));
                }
                searchRequest.facets[facet] = tempArray;
            }
        }

        HawkSearch.searchRequest = searchRequest;

        /*
         * Sets the body Request Query parameter for the type of request
         *
         * { RequestType: 'ConceptSearch' } => { Keyword: string, ... }  - Search by concept
         * { RequestType: 'ImageSearch' }   => { ImageText: string, ...} - Search an image by text
         * { RequestType: 'ImageSearch' }   => { ImageData: string, ... } - Search an image with a Base64 string
         */

        const performImageSearch: boolean = searchRequest.requestType === 'ImageData';

        const rawRequestQuery = performImageSearch
            ? 'ImageData'
            : searchRequest.requestType === 'ImageSearch'
                ? 'ImageText'
                :'Keyword'
        /*
         * Sets the RequestType parameter to ImageSearch if requestType is 'ImageData' so an Image search with a base64 is performed
         */
        const rawRequestType = performImageSearch ? 'ImageSearch' : searchRequest.requestType

        const rawSearchRequest: RawSearchRequest = {
            ClientData: {
                UserAgent: window.navigator.userAgent,
                VisitorId: this.getVisitorId(),
                VisitId: this.getVisitId()
            },
            ClientGuid: HawkSearch.config.clientId,
            CustomURL: searchRequest.url,
            RequestType: rawRequestType || 'DefaultSearch', // DefaultSearch is default for Keyword
            IgnoreSpellcheck: searchRequest.disableSpellcheck,
            IndexName: HawkSearch.config.index,
            [rawRequestQuery || 'Keyword']: searchRequest.query, // Keyword is the default param for Keyword search
            FacetSelections: searchRequest.facets,
            MaxPerPage: searchRequest.pageSize,
            PageNo: searchRequest.page,
            SearchWithin: searchRequest.searchWithin,
            SortBy: searchRequest.sort,
            Variants: {
                CountFacetHitOnChild: HawkSearch.config.variants?.baseFacetCountOnVariants
            }
        };

        if (HawkSearch.config.debug) {
            console.log('hawksearch:debug ', { rawSearchRequest })
        };

        try {
            this.triggerEvent('hawksearch:before-search-executed', rawSearchRequest);
            const rawSearchResponse = await this.httpPost<RawSearchResponse>('/api/v2/search', rawSearchRequest);

            this.triggerEvent('hawksearch:after-search-executed', rawSearchResponse);

            const searchResponse = this.convertResponse(searchRequest, rawSearchResponse);

            HawkSearch.searchResponse = searchResponse;

            trackingService.trackSearch(searchRequest.query, newSearch);

            if (!performImageSearch) {
                this.setQueryString(searchResponse);
            }
            this.setSeoElements(searchResponse);

            if (newSearch && searchResponse.redirect) {
                if (searchResponse.redirect.url) {
                    if (searchResponse.redirect.target !== '_blank') {
                        this.shouldRenderUI = false;
                    }
                    if (searchResponse.redirect.target === '_blank') {
                        window.open(searchResponse.redirect.url, '_blank');
                    } else if (searchResponse.redirect.target === '_parent') {
                        window.open(searchResponse.redirect.url, '_parent');
                    } else if (searchResponse.redirect.target === '_top') {
                        window.open(searchResponse.redirect.url, '_top');
                    } else {
                        window.open(searchResponse.redirect.url, '_self');
                    }
                }
            }

            if (executeDefaultBindings && this.shouldRenderUI) {
                this.bindModifiedQueryComponent(searchResponse);
                this.bindQuerySuggestionsComponent(searchResponse);
                this.bindSearchFieldComponent(searchResponse);
                this.bindSearchResultsListComponent(searchResponse);
            }

            this.triggerEvent('hawksearch:search-completed', searchResponse);

            return searchResponse;
        } catch {
            throw new Error('Error retrieving search response');
        }
    }

    private ensureSearchRequest(): void {
        if (!HawkSearch.searchRequest) {
            throw new Error('You cannot refine search results until an initial search has been executed');
        }
    }

    // #endregion Search Operations

    // #region Model Binding

    private convertResponse(searchRequest: SearchRequest, rawSearchResponse: RawSearchResponse): SearchResponse {
        const getFacetType = (facet: RawFacet): FacetType => {
            if (facet.FieldType === 'tab') {
                return 'tabs';
            }

            switch (facet.FacetType.toLowerCase()) {
                case 'checkbox':
                case 'nestedcheckbox':
                    return 'checkbox';
                case 'color':
                    return 'color';
                case 'link':
                    return 'link';
                case 'openrange':
                    if (facet.DataType === 'datetime') {
                        return 'date-range';
                    } else if (facet.IsCurrency || facet.IsNumeric) {
                        return 'numeric-range';
                    }
                case 'recentsearches':
                    return 'recent-searches';
                case 'related':
                    return 'related-searches';
                case 'search':
                    return 'search';
                case 'size':
                    return 'size';
                case 'slider':
                    return 'range-slider';
                case 'swatch':
                    return 'color';
                default:
                    return 'unsupported';
            }
        };

        const getFacetScrolling = (facet: RawFacet): FacetScrolling | undefined => {
            if (facet.DisplayType !== 'scrolling') {
                return undefined;
            }

            return {
                height: facet.ScrollHeight || undefined,
                threshold: facet.ScrollThreshold || 0
            };
        };

        const getFacetRange = (facet: RawFacet): DateFacetRange | NumericFacetRange | undefined => {
            const facetType = getFacetType(facet);
            const rangeFacetTypes: Array<FacetType> = ['date-range', 'numeric-range', 'range-slider'];

            if (!rangeFacetTypes.includes(facetType)) {
                return undefined;
            }

            if (facet.DataType === 'datetime') {
                return {
                    type: 'date',
                    min: new Date(Date.parse(facet.Values![0].RangeMin)),
                    max: new Date(Date.parse(facet.Values![0].RangeMax)),
                    start: new Date(Date.parse(facet.Values![0].RangeStart)),
                    end: new Date(Date.parse(facet.Values![0].RangeEnd))
                };
            } else if (facet.IsCurrency || facet.IsNumeric) {
                return {
                    type: 'numeric',
                    min: parseFloat(facet.Values![0].RangeMin),
                    max: parseFloat(facet.Values![0].RangeMax),
                    start: parseFloat(facet.Values![0].RangeStart),
                    end: parseFloat(facet.Values![0].RangeEnd)
                };
            }

            return undefined;
        };

        const getFacetValueImage = (facet: RawFacet, value: RawFacetValue): string | undefined => {
            const rangeValue = facet.Ranges?.find((r) => r.Value.toLowerCase() === value.Value.toLowerCase());

            if (!rangeValue?.AssetFullUrl) {
                return undefined;
            }

            return this.getFullUrl(rangeValue.AssetFullUrl, HawkSearch.config.urlPrefixes?.assets);
        };

        const getFacetValueColor = (facet: RawFacet, value: RawFacetValue): FacetValueColor | undefined => {
            let swatchValue = facet.SwatchData?.find((v) => v.Value.toLowerCase() === value.Value.toLowerCase());

            if (!swatchValue) {
                swatchValue = facet.SwatchData?.find((v) => v.IsDefault);
            }

            if (!swatchValue) {
                return undefined;
            }

            return {
                name: value.Label,
                hex: swatchValue.Color || swatchValue.Value,
                imageUrl: this.getFullUrl(swatchValue.AssetUrl || swatchValue.AssetName, HawkSearch.config.urlPrefixes?.assets)
            };
        };

        const getFacetValues = (
            facet: RawFacet,
            values: Array<RawFacetValue> | undefined,
            selection: RawSearchSelection | undefined
        ): Array<FacetValue> | undefined => {
            const facetType = getFacetType(facet);

            if (facetType === 'recent-searches') {
                const recentQueries: Array<string> = this.getRecentQueries();

                return recentQueries.map((s) => ({
                    title: s
                }));
            }

            return values?.map((v) => ({
                title: v.Label,
                imageUrl: getFacetValueImage(facet, v),
                value: v.Value,
                color: getFacetValueColor(facet, v),
                count: v.Count,
                level: v.Level,
                selected: v.Selected,
                excluded: selection?.Items?.some((s) => s.Value === `${this.facetExclusionPrefix}${v.Value}`),
                children: getFacetValues(facet, v.Children, selection)
            }));
        };

        const getFacetVisible = (facet: RawFacet): boolean => {
            const facetType = getFacetType(facet);

            if (facetType === 'recent-searches') {
                const values = getFacetValues(facet, undefined, undefined);

                return !!values?.length;
            }

            return facet.IsVisible ?? true;
        };

        const getFacetTruncation = (facet: RawFacet): FacetTruncation | undefined => {
            if (facet.DisplayType !== 'truncating') {
                return undefined;
            }

            return {
                threshold: parseFloat(facet.TruncateThreshold as any) || 0
            };
        };

        const getSearchResultsItem = (item: RawSearchResultsItem): SearchResultsItem | FeaturedItemsContentItem => {
            const getType = (document: RawSearchResultsItemDocument, fieldMappings: string | Array<string>): ItemType => {
                if (typeof fieldMappings === 'string') {
                    fieldMappings = [fieldMappings];
                }

                const productValues = (HawkSearch.config.search?.itemTypes?.productValues ?? ['item', 'product']).map((t) => t.toLowerCase());

                for (const fieldMapping of fieldMappings) {
                    let value = get(document, fieldMapping) as any;

                    if (value && value instanceof Array) {
                        value = value[0];
                    }

                    if (value && productValues.includes(value.toLowerCase())) {
                        return 'product';
                    }
                }

                return HawkSearch.config.search?.itemTypes?.default ?? 'content';
            };

            const variants = this.getVariants(item.Document);

            return {
                attributes: Object.fromEntries(
                    Object.entries(item.Document).filter(([key, value]) => !['hawk_child_attributes', 'hawk_child_attributes_hits'].includes(key))
                ) as any,
                description: this.getString(item.Document, this.fieldMappings.description),
                id: item.DocId,
                imageUrl: this.getUrl(item.Document, this.fieldMappings.imageUrl, HawkSearch.config.urlPrefixes?.content),
                pinned: item.IsPin ?? false,
                price: this.getNumber(item.Document, this.fieldMappings.price) || undefined,
                rating: this.getNumber(item.Document, this.fieldMappings.rating),
                salePrice: this.getNumber(item.Document, this.fieldMappings.salePrice) || undefined,
                score: item.Score,
                sku: this.getString(item.Document, this.fieldMappings.sku),
                selectedVariant: variants.selectedItem,
                title: this.getString(item.Document, this.fieldMappings.title)!,
                type: getType(item.Document, this.fieldMappings.type),
                url: this.getUrl(item.Document, this.fieldMappings.url, HawkSearch.config.urlPrefixes?.content)!,
                variants: variants.items,
                visible: item.IsVisible ?? true
            };
        };

        const getFacetSearch = (facet: RawFacet): FacetSearch | undefined => {
            if (!facet.IsSearch) {
                return undefined;
            }

            return {
                threshold: parseFloat(facet.SearchThreshold as any) || 0
            };
        };

        const getSelectedFacets = (): Array<SelectedFacet> => {
            return [
                ...Object.keys(rawSearchResponse.Selections ?? []).map((field) => {
                    const selection = rawSearchResponse.Selections![field];
                    const facet = rawSearchResponse.Facets?.find((f) => (f.ParamName || f.Field) === field);
                    const excludedRegex = new RegExp('^' + this.facetExclusionPrefix);

                    return {
                        field: field,
                        currency: facet?.IsCurrency ?? false,
                        title: selection.Label,
                        values: selection.Items.map((i) => ({
                            title: i.Label.replace(excludedRegex, ''),
                            value: i.Value,
                            excluded: excludedRegex.test(i.Value)
                        }))
                    };
                }),
                ...getSearchWithinSelectedFacets()
            ];
        };

        const getSearchWithinSelectedFacets = (): Array<SelectedFacet> => {
            const facetName = 'searchWithin';
            const facet = rawSearchResponse.Facets?.find((f) => f.Field === facetName);

            if (!searchRequest.searchWithin || !facet) {
                return [];
            }

            return [
                {
                    field: facetName,
                    title: facet.Name,
                    currency: false,
                    values: [
                        {
                            title: searchRequest.searchWithin,
                            value: searchRequest.searchWithin,
                            excluded: false
                        }
                    ]
                }
            ];
        };

        const getContentItem = (rawItem: RawFeaturedItem | RawMerchandisingItem, prefix: '' | 'Mobile' | 'Tablet', zone?: string): ContentType => {
            if ((prefix === 'Mobile' && !rawItem.IsMobile) || (prefix === 'Tablet' && !rawItem.IsTablet)) {
                prefix = '';
            }

            const contentItem: ContentType = {
                campaignId: rawItem.CampaignId,
                id: rawItem.BannerId,
                title: rawItem.Title,
                trackingEnabled: rawItem.IsTrackingEnabled ?? true,
                type: (rawItem as any)[prefix + 'ContentType'],
                zone: zone ?? rawItem.Zone
            };

            switch (contentItem.type) {
                case 'custom': {
                    const custom = contentItem as CustomContent;

                    custom.content = (rawItem as any)[prefix + 'Output'];

                    break;
                }
                case 'featured': {
                    const featuredItems = contentItem as FeaturedItemsContent;

                    featuredItems.type = 'featured-items';
                    featuredItems.items = [
                        ...((rawItem as RawFeaturedItem).Items ?? []).map(getSearchResultsItem),
                        ...((rawItem as RawFeaturedItem).FeaturedItems ?? []).map(getSearchResultsItem)
                    ];

                    break;
                }
                case 'image': {
                    const image = contentItem as ImageContent;

                    image.image = {
                        url: this.getFullUrl((rawItem as any)[prefix + 'ImageUrl'], HawkSearch.config.urlPrefixes?.assets)!,
                        height: (rawItem as any)[prefix + 'Height'] || undefined,
                        width: (rawItem as any)[prefix + 'Width'] || undefined,
                        altText: (rawItem as any)[prefix + 'AltTag'],
                        title: (rawItem as any)[prefix + 'AltTag']
                    };

                    image.link = (rawItem as any)[prefix + 'ForwardUrl']
                        ? {
                              url: (rawItem as any)[prefix + 'ForwardUrl'],
                              target: (rawItem as any)[prefix + 'Target'] || '_self'
                          }
                        : undefined;

                    break;
                }
                case 'widget': {
                    const widgetType = (rawItem as any)[prefix + 'WidgetType'];

                    switch (widgetType) {
                        case 'PopularSearchesWidget': {
                            const popularSearches = contentItem as PopularQueriesContent;

                            popularSearches.type = 'popular-queries';

                            const min = Math.min(...(rawItem as any)[prefix + 'Output'].map((k: any) => k.count));
                            const max = Math.max(...(rawItem as any)[prefix + 'Output'].map((k: any) => k.count));
                            const range = max - min;
                            const weights = 5;
                            const weightThresholds = new Array(weights).fill(0);

                            weightThresholds.forEach((v, i) => {
                                if (i === weights - 1) {
                                    weightThresholds[i] = 0;
                                } else {
                                    weightThresholds[i] = Math.floor(range / (i + 1)) + min;
                                }
                            });

                            popularSearches.items =
                                (rawItem as any)[prefix + 'Output'] instanceof Array
                                    ? (rawItem as any)[prefix + 'Output']
                                          .map(
                                              (k: any): PopularQueriesContentItem => ({
                                                  query: k.keyword,
                                                  count: k.count,
                                                  weight: weights - weightThresholds.findIndex((t) => k.count >= t)
                                              })
                                          )
                                          .sort((x: any, y: any) => x.query.localeCompare(y.query))
                                    : [];

                            break;
                        }
                        default: {
                            const customWidget = contentItem as CustomWidgetContent;

                            customWidget.type = 'custom-widget';
                            customWidget.data = rawItem.AltTag;
                        }
                    }
                }
            }

            return contentItem;
        };

        const getContentZones = (): ContentZones => {
            const zones: ContentZones = {};

            rawSearchResponse.FeaturedItems?.Items.forEach((i) => {
                const zone = zones[i.Zone] ?? {
                    name: i.Zone
                };

                const item: ContentZoneItem = {
                    mobile: getContentItem(i, ''),
                    tablet: getContentItem(i, ''),
                    desktop: getContentItem(i, '')
                };

                zone.items = [...(zone.items ?? []), item];

                zones[i.Zone] = zone;
            });

            rawSearchResponse.Merchandising?.Items.forEach((i) => {
                const zone = zones[i.Zone] ?? {
                    name: i.Zone
                };

                const item: ContentZoneItem = {
                    mobile: getContentItem(i, 'Mobile'),
                    tablet: getContentItem(i, 'Tablet'),
                    desktop: getContentItem(i, '')
                };

                zone.items = [...(zone.items ?? []), item];

                zones[i.Zone] = zone;
            });

            rawSearchResponse.PageContent?.forEach((z) => {
                const zone = zones[z.ZoneName] ?? {
                    name: z.ZoneName
                };

                z.Items.forEach((i) => {
                    const item: ContentZoneItem = {
                        mobile: getContentItem(i, 'Mobile', zone.name),
                        tablet: getContentItem(i, 'Tablet', zone.name),
                        desktop: getContentItem(i, '', zone.name)
                    };

                    zone.items = [...(zone.items ?? []), item];
                });

                zones[z.ZoneName] = zone;
            });

            return zones;
        };

        const searchResponse: SearchResponse = {
            content: {
                breadcrumbs: rawSearchResponse.Breadcrumb || undefined,
                customHtml: rawSearchResponse.CustomHtml || undefined,
                heading: rawSearchResponse.PageHeading || undefined
            },
            contentZones: getContentZones(),
            customLayout: rawSearchResponse.PageLayoutHtml || undefined,
            facets: rawSearchResponse.Facets?.map((f) => ({
                id: f.FacetId,
                type: getFacetType(f),
                title: f.Name,
                field: f.ParamName || f.Field,
                collapsible: f.IsCollapsible ?? false,
                collapsed: f.IsCollapsedDefault,
                displayCount: f.ShowItemsCount,
                range: getFacetRange(f),
                scrolling: getFacetScrolling(f),
                search: getFacetSearch(f),
                searchWithin: searchRequest.searchWithin,
                tooltip: this.stripHtml(f.Tooltip ?? '') || undefined,
                truncation: getFacetTruncation(f),
                values: getFacetValues(f, f.Values, rawSearchResponse.Selections?.[f.ParamName || f.Field]),
                visible: getFacetVisible(f)
            })).filter((f) => f.type !== 'unsupported'),
            modifiedQuery: rawSearchResponse.AdjustedKeyword,
            pagination: rawSearchResponse.Pagination
                ? {
                      page: rawSearchResponse.Pagination.CurrentPage,
                      pageSize: rawSearchResponse.Pagination.MaxPerPage,
                      totalPages: rawSearchResponse.Pagination.NofPages,
                      totalResults: rawSearchResponse.Pagination.NofResults,
                      maxPageLinks: rawSearchResponse.Pagination.NumberOfPageLinks > 0 ? rawSearchResponse.Pagination.NumberOfPageLinks : undefined,
                      displayFirstLink: rawSearchResponse.Pagination.IsShowFirstLink,
                      displayLastLink: rawSearchResponse.Pagination.IsShowLastLink,
                      options:
                          rawSearchResponse.Pagination.Items.map((i) => ({
                              title: i.Label,
                              pageSize: i.PageSize,
                              selected: i.Selected,
                              default: i.Default
                          })) ?? []
                  }
                : undefined,
            query: rawSearchResponse.Keyword,
            querySuggestions: rawSearchResponse.DidYouMean,
            redirect: rawSearchResponse.Redirect
                ? {
                      url: rawSearchResponse.Redirect.Location,
                      target: rawSearchResponse.Redirect.Target
                  }
                : undefined,
            results: rawSearchResponse.Results?.map(getSearchResultsItem),
            selectedFacets: getSelectedFacets(),
            seo: {
                canonicalUrl: rawSearchResponse.RelCanonical,
                description: rawSearchResponse.MetaDescription,
                keywords: rawSearchResponse.MetaKeywords,
                robots: rawSearchResponse.MetaRobots,
                title: rawSearchResponse.HeaderTitle
            },
            success: rawSearchResponse.Success,
            sorting: {
                value: rawSearchResponse.Sorting?.Value,
                options:
                    rawSearchResponse.Sorting?.Items.map((i) => ({
                        title: i.Label,
                        value: i.Value,
                        selected: i.Selected,
                        default: i.IsDefault
                    })) ?? []
            },
            trackingId: rawSearchResponse.TrackingId
        };

        const url = new URL(this.getSearchUrl(searchRequest));

        if (!searchResponse.seo) {
            searchResponse.seo = {};
        }

        searchResponse.seo.canonicalUrl = (searchResponse.seo.canonicalUrl?.replace(/\?.*$/, '') || url.origin + url.pathname) + url.search;

        return searchResponse;
    }

    // #endregion Model Binding

    // #region Events

    bindComponents(searchResponse: SearchResponse): void {
        this.bindLandingPageComponent(searchResponse);
        this.bindSearchResultsComponent(searchResponse);

        setTimeout(() => {
            this.bindFacetsListComponent(searchResponse);
            this.bindModifiedQueryComponent(searchResponse);
            this.bindPageSizeComponent(searchResponse);
            this.bindPaginationComponent(searchResponse);
            this.bindQuerySuggestionsComponent(searchResponse);
            this.bindSearchFieldComponent(searchResponse);
            this.bindSearchResultsListComponent(searchResponse);
            this.bindSelectedFacetsComponent(searchResponse);
            this.bindSortingComponent(searchResponse);
            this.bindTabsComponent(searchResponse);
            this.bindZoneComponent(searchResponse);
        });
    }

    bindFacetsListComponent(searchResponse: SearchResponse | undefined): void {
        this.triggerBindEvent('facets-list', searchResponse?.facets);
    }

    bindLandingPageComponent(searchResponse: SearchResponse | undefined): void {
        this.triggerBindEvent('landing-page', searchResponse);
    }

    bindModifiedQueryComponent(searchResponse: SearchResponse | undefined): void {
        const data: ModifiedQueryData = {
            display: !!searchResponse?.query && !!searchResponse?.modifiedQuery && HawkSearch.searchRequest!.newRequest,
            query: searchResponse?.query,
            modifiedQuery: searchResponse?.modifiedQuery
        };

        this.triggerBindEvent('modified-query', data);
    }

    bindPageSizeComponent(searchResponse: SearchResponse | undefined): void {
        this.triggerBindEvent('page-size', searchResponse?.pagination?.options);
    }

    bindPaginationComponent(searchResponse: SearchResponse | undefined): void {
        this.triggerBindEvent('pagination', searchResponse?.pagination);
    }

    bindSearchFieldComponent(searchResponse: SearchResponse | undefined): void {
        this.triggerBindEvent('search-field', searchResponse);
    }

    bindSearchResultsComponent(searchResponse: SearchResponse | undefined): void {
        this.triggerBindEvent('search-results', searchResponse);
    }

    bindSearchResultsListComponent(searchResponse: SearchResponse | undefined): void {
        this.triggerBindEvent('search-results-list', searchResponse?.results);
    }

    bindQuerySuggestionsComponent(searchResponse: SearchResponse | undefined): void {
        const data: QuerySuggestionsData = {
            display: !!searchResponse?.querySuggestions?.length && HawkSearch.searchRequest!.newRequest,
            querySuggestions: searchResponse?.querySuggestions
        };

        this.triggerBindEvent('query-suggestions', data);
    }

    bindSortingComponent(searchResponse: SearchResponse | undefined): void {
        this.triggerBindEvent('sorting', searchResponse?.sorting);
    }

    bindSelectedFacetsComponent(searchResponse: SearchResponse | undefined): void {
        this.triggerBindEvent('selected-facets', searchResponse?.selectedFacets);
    }

    bindTabsComponent(searchResponse: SearchResponse | undefined): void {
        this.triggerBindEvent(
            'tabs',
            searchResponse?.facets?.find((f) => f.type === 'tabs')
        );
    }

    bindZoneComponent(searchResponse: SearchResponse | undefined): void {
        this.triggerBindEvent('content-zone', searchResponse);
    }

    // #endregion Events

    // #region Query String

    getRequestFromUrl(): SearchRequest {
        const url = new URL(window.location.href);
        const nonFacetParams = Object.keys(this.queryStringParams);
        const facets: SelectedFacets = {};

        for (const param of Array.from(url.searchParams.keys()).filter((p) => !nonFacetParams.includes(p))) {
            const field = param;

            facets[field] = url.searchParams.getAll(param);
        }

        return {
            disableSpellcheck: this.getBooleanParameter(url.searchParams.get(this.queryStringParams.disableSpellcheck)),
            facets: facets,
            newRequest: true,
            pageSize: this.getPositiveIntegerParameter(url.searchParams.get(this.queryStringParams.pageSize)),
            page: this.getPositiveIntegerParameter(url.searchParams.get(this.queryStringParams.page)),
            query: this.getStringParameter(url.searchParams.get(this.queryStringParams.query)),
            searchWithin: this.getStringParameter(url.searchParams.get(this.queryStringParams.searchWithin)),
            sort: this.getStringParameter(url.searchParams.get(this.queryStringParams.sort))
        };
    }

    private setQueryString(searchResponse: SearchResponse): void {
        if (!HawkSearch.searchRequest) {
            return;
        }

        const url = this.getSearchUrl(HawkSearch.searchRequest);

        const state: HawkSearchState = {
            searchRequest: HawkSearch.searchRequest,
            searchResponse: searchResponse
        };

        if (url.toString() === window.location.href) {
            window.history.replaceState(state, '');
        } else {
            window.history.pushState(state, '', url.toString());
        }
    }

    private getSearchUrl(searchRequest: SearchRequest): string {
        const searchPage = window.location.pathname === this.searchUrl || !!searchRequest.url;
        const url = new URL(searchPage ? window.location.href : this.searchUrl, window.location.origin);
        const nonFacetParams = Object.keys(this.queryStringParams);

        url.searchParams.delete(this.queryStringParams.query);

        if (searchRequest.query) {
            url.searchParams.set(this.queryStringParams.query, searchRequest.query);
        }

        // Remove facet parameters
        Array.from(url.searchParams.keys())
            .filter((p) => !nonFacetParams.includes(p))
            .forEach((p) => url.searchParams.delete(p));

        if (searchRequest.disableSpellcheck) {
            url.searchParams.set(this.queryStringParams.disableSpellcheck, searchRequest.disableSpellcheck.toString());
        }

        if (searchRequest.facets) {
            Object.keys(searchRequest.facets).sort((a, b) => a > b ? 1 : -1).forEach((key) => {
                //@ts-ignore
                url.searchParams.append(key, searchRequest.facets[key])
            });
        }

        url.searchParams.delete(this.queryStringParams.searchWithin);

        if (searchRequest.searchWithin) {
            url.searchParams.set(this.queryStringParams.searchWithin, searchRequest.searchWithin);
        }

        url.searchParams.delete(this.queryStringParams.sort);

        if (searchRequest.sort) {
            url.searchParams.set(this.queryStringParams.sort, searchRequest.sort);
        }

        url.searchParams.delete(this.queryStringParams.pageSize);

        if (searchRequest.pageSize) {
            url.searchParams.set(this.queryStringParams.pageSize, searchRequest.pageSize.toString());
        }

        url.searchParams.delete(this.queryStringParams.page);

        if (searchRequest.page) {
            url.searchParams.set(this.queryStringParams.page, searchRequest.page.toString());
        }

        return url.toString();
    }

    private getBooleanParameter(input: string | null): boolean | undefined {
        if (input === null) {
            return undefined;
        }

        return input === 'true';
    }

    private getPositiveIntegerParameter(input: string | null): number | undefined {
        if (!input) {
            return undefined;
        }

        const value = parseInt(input);

        return value > 0 ? value : undefined;
    }

    private getStringParameter(input: string | null): string | undefined {
        if (!input || !input.trim()) {
            return undefined;
        }

        return input.trim();
    }

    // #endregion Query String

    // #region Recent Queries

    private getRecentQueries(): Array<string> {
        const json = sessionStorage.getItem('recent-queries');

        return json ? JSON.parse(json) : [];
    }

    private saveRecentQueries(recentQueries: Array<string>): void {
        const json = JSON.stringify(recentQueries);

        sessionStorage.setItem('recent-queries', json);
    }

    // #endregion Recent Queries

    // #region HTML Elements

    protected setSeoElements(searchResponse: SearchResponse): void {
        if (!searchResponse?.seo) {
            return;
        }

        let title = searchResponse.seo.title;

        if (title && HawkSearch.config.seo?.title?.prefix) {
            title = `${HawkSearch.config.seo.title.prefix}${title}`;
        }

        if (title && HawkSearch.config.seo?.title?.suffix) {
            title = `${title}${HawkSearch.config.seo.title.suffix}`;
        }

        this.setTitle(title);
        this.setLinkTag('canonical', searchResponse.seo.canonicalUrl);
        this.setMetaTag('description', searchResponse.seo.description);
        this.setMetaTag('keywords', searchResponse.seo.keywords);
        this.setMetaTag('robots', searchResponse.seo.robots);
    }

    private removeElement(selector: string): void {
        const element = document.head.querySelector(selector);

        if (element) {
            element.remove();
        }
    }

    private setLinkTag(rel: string, href: string | undefined): void {
        if (!href) {
            return;
        }

        this.removeElement(`link[rel="${rel}"]`);

        const element = document.createElement('link');

        element.rel = rel;
        element.href = href;

        document.head.append(element);
    }

    private setMetaTag(name: string, content: string | undefined): void {
        if (!content) {
            return;
        }

        this.removeElement(`meta[name="${name}"]`);

        const element = document.createElement('meta');

        element.name = name;
        element.content = content;

        document.head.append(element);
    }

    private setTitle(title: string | undefined): void {
        if (!title) {
            return;
        }

        document.title = title;
    }

    // #endregion HTML Elements
}

export const searchService = new SearchService();
