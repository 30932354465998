import { debounce } from 'lodash-es';
import { BaseComponent } from '@components';
import { HawkSearchComponents, HawkSearchGlobal, SearchFieldComponentConfig } from '@configuration';
import { SearchFieldComponentModel, SearchResponse } from '@models';
import { searchService } from '@services';
import defaultHtml from './imagesearch-field.component.hbs';

declare let HawkSearch: HawkSearchGlobal;

/**
 *
 * ## Tag
 * The tag for this component is `<hawksearch-imagesearch-field>`.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-input | |
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./imagesearch-field.component.hbs}
 *
 * @category Search
 */
export class ImageSearchFieldComponent extends BaseComponent<SearchFieldComponentConfig, SearchResponse, SearchFieldComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'imagesearch-field';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = true;

    private previousValue = '';

    override connectedCallback(): void {
        super.connectedCallback();
    }

    disconnectedCallback(): void {
        super.disconnectedCallback();

        this.rootElement.ownerDocument!.removeEventListener('click', this.connectedCallback);
    }

    protected override getContentModel(): SearchFieldComponentModel {
        return {
            query: this.data?.query,
            strings: {
                placeholder: this.configuration?.strings?.placeholder ?? 'Describe an image you want to search'
            }
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-input]').forEach((e) => {
            const onKeyUp = (event: KeyboardEvent): void => {
                const element = e as HTMLInputElement; // Not using event.currentTarget as that gets lost when using lodash debounce
                const query = element.value.trim();
                if (query === this.previousValue) {
                    return;
                }

                if (query && query.length > 3) {
                    this.previousValue = query;
                    searchService.query({ queryString: query, requestType: 'ImageSearch' });
                }
            };

            e.addEventListener('keyup', debounce(onKeyUp, 350) as unknown as EventListener);
        });
    }
}
