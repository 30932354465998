// import { debounce } from 'lodash-es';
import { BaseComponent } from '@components';
import { HawkSearchComponents, HawkSearchGlobal, ImageFinderFieldComponentConfig } from '@configuration';
import { ImageFinderFieldComponentModel, SearchResponse } from '@models';
import { searchService } from '@services';
import defaultHtml from './imagefinder-field.component.hbs';

declare let HawkSearch: HawkSearchGlobal;

/**
 * The Search component contains a file input element which triggers the {@link Components.AutocompleteComponent | Autocomplete component} as the user types and executes a new search when the user hits the `enter` key.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-imagefinder-field>`.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./imagefinder-field.component.hbs}
 *
 * @category Search
 */
export class ImageFinderFieldComponent extends BaseComponent<ImageFinderFieldComponentConfig, SearchResponse, ImageFinderFieldComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'imagefinder-field';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = true;

    private clickEventHandler!: (event: MouseEvent) => void;
    private previousValue = '';
    private maxSize: number = 50 * 1024 * 1024; // 50MB in bytes
    private allowedExtensions: string[] = ['jpg', 'jpeg', 'png'];

    private handleFiles (files: FileList): void {
        const file = files[0];
        const extension = file.name.split('.').pop()?.toLowerCase();

        if (
            file.type.startsWith('image/') &&
            file.size <= this.maxSize &&
            this.allowedExtensions.includes(extension!)
        ) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.src = reader.result as string;
                this.rootElement.querySelectorAll('[hawksearch-imagefinder-display]').forEach((e) => {
                    if (e.lastChild) {
                        e.removeChild(e.lastChild);
                    }
                    e.appendChild(img);
                });
                const base64Data = e.target?.result;
                if (base64Data) {
                    this.previousValue = base64Data as string;
                    searchService.query({
                        queryString: base64Data as string,
                        requestType: 'ImageData'
                    });
                }
            };
            reader.readAsDataURL(file);
        } else {
            console.warn('File type, size, or extension not supported:', file.type, file.size, extension);
        }
    };

    override connectedCallback(): void {
        super.connectedCallback();

        this.clickEventHandler = (event: Event): void => {
            const element = event.target as HTMLElement;
            const searchElement = !!element.closest('hawksearch-imagefinder-droparea');
        };

        this.rootElement.ownerDocument!.addEventListener('click', this.clickEventHandler);
    }

    disconnectedCallback(): void {
        super.disconnectedCallback();
        this.rootElement.ownerDocument!.removeEventListener('click', this.connectedCallback);
    }

    protected override getContentModel(): ImageFinderFieldComponentModel {
        return {
            query: this.data?.query,
            strings: {
                dragImageMessage: this.configuration?.strings?.dragImageMessage ?? 'Drag an image here or ',
                uploadImageMessage: this.configuration?.strings?.uploadImageMessage ?? 'upload an image',
                dropImageMessage: this.configuration?.strings?.dropImageMessage ?? 'Drop an image here',
                errorMessage: this.configuration?.strings?.errorMessage ?? 'Drop an image here'
            }
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-imagefinder-fileinput]').forEach((e) => {
            e.addEventListener('change', (e): void => {
                e.preventDefault();
                e.stopPropagation();
                const element = e.currentTarget as HTMLInputElement;
                const files: FileList = element.files!;
                this.handleFiles(files);
            });
        });

        this.rootElement.querySelectorAll('[hawksearch-imagefinder-droparea]').forEach((e) => {
            e.addEventListener('drop', ((event:  DragEvent): void => {
                event.preventDefault();
                event.stopPropagation();
                const draggedData = event.dataTransfer!;
                const files: FileList = draggedData.files!;
                this.handleFiles(files);
                const element = e as HTMLInputElement;
                element.classList.remove('dragging-state');
            }) as unknown as EventListener);

            e.addEventListener('dragover', ((event: MouseEvent): void => {
                event.preventDefault();
            }) as unknown as EventListener);

            e.addEventListener('dragenter', ((event: MouseEvent): void => {
                event.preventDefault();
                event.stopPropagation();
                const element = e as HTMLInputElement;
                element.classList.add('dragging-state');
            }) as unknown as EventListener);

            e.addEventListener('dragleave', ((event: DragEvent): void => {
                event.preventDefault();
                event.stopPropagation();
                const element = e as HTMLInputElement;
                element.classList.remove('dragging-state');
            }) as unknown as EventListener);
        });
    }
}
